#euiBvmY81Mn1 #euiBvmY81Mn2_to {
	animation: euiBvmY81Mn2_to__to 2000ms linear 1 normal forwards
}

@keyframes euiBvmY81Mn2_to__to {
	0% {
		offset-distance: 0%
	}

	25% {
		offset-distance: 100%
	}

	100% {
		offset-distance: 100%
	}
}

#euiBvmY81Mn1 #euiBvmY81Mn2_ts {
	animation: euiBvmY81Mn2_ts__ts 2000ms linear 1 normal forwards
}

@keyframes euiBvmY81Mn2_ts__ts {
	0% {
		transform: scale(0.2, 0.2)
	}

	25% {
		transform: scale(1, 1)
	}

	100% {
		transform: scale(1, 1)
	}
}

#euiBvmY81Mn1 #euiBvmY81Mn3_ts {
	animation: euiBvmY81Mn3_ts__ts 2000ms linear 1 normal forwards
}

@keyframes euiBvmY81Mn3_ts__ts {
	0% {
		transform: translate(33.61415px, 40.8901px) scale(0, 0)
	}

	30% {
		transform: translate(33.61415px, 40.8901px) scale(0, 0)
	}

	45% {
		transform: translate(33.61415px, 40.8901px) scale(1, 1)
	}

	100% {
		transform: translate(33.61415px, 40.8901px) scale(1, 1)
	}
}

#euiBvmY81Mn1 #euiBvmY81Mn4_ts {
	animation: euiBvmY81Mn4_ts__ts 2000ms linear 1 normal forwards
}

@keyframes euiBvmY81Mn4_ts__ts {
	0% {
		transform: translate(35.459049px, 51.6586px) scale(0, 0)
	}

	25% {
		transform: translate(35.459049px, 51.6586px) scale(0, 0)
	}

	40% {
		transform: translate(35.459049px, 51.6586px) scale(1, 1)
	}

	100% {
		transform: translate(35.459049px, 51.6586px) scale(1, 1)
	}
}

#euiBvmY81Mn1 #euiBvmY81Mn5_ts {
	animation: euiBvmY81Mn5_ts__ts 2000ms linear 1 normal forwards
}

@keyframes euiBvmY81Mn5_ts__ts {
	0% {
		transform: translate(40.268652px, 50.915199px) scale(0, 0)
	}

	20% {
		transform: translate(40.268652px, 50.915199px) scale(0, 0)
	}

	35% {
		transform: translate(40.268652px, 50.915199px) scale(1, 1)
	}

	100% {
		transform: translate(40.268652px, 50.915199px) scale(1, 1)
	}
}

#euiBvmY81Mn1 #euiBvmY81Mn6_ts {
	animation: euiBvmY81Mn6_ts__ts 2000ms linear 1 normal forwards
}

@keyframes euiBvmY81Mn6_ts__ts {
	0% {
		transform: translate(44.03405px, 52.544249px) scale(0, 0)
	}

	15% {
		transform: translate(44.03405px, 52.544249px) scale(0, 0)
	}

	30% {
		transform: translate(44.03405px, 52.544249px) scale(1, 1)
	}

	100% {
		transform: translate(44.03405px, 52.544249px) scale(1, 1)
	}
}

#euiBvmY81Mn1 #euiBvmY81Mn7_ts {
	animation: euiBvmY81Mn7_ts__ts 2000ms linear 1 normal forwards
}

@keyframes euiBvmY81Mn7_ts__ts {
	0% {
		transform: translate(69px, 32px) scale(0, 0)
	}

	35% {
		transform: translate(69px, 32px) scale(0, 0)
	}

	50% {
		transform: translate(69px, 32px) scale(1, 1)
	}

	100% {
		transform: translate(69px, 32px) scale(1, 1)
	}
}